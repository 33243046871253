@import 'reset.scss';

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
  url("../../fonts/SourceSansPro-Bold.ttf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
  url("../../fonts/SourceSansPro-Regular.ttf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url("../../fonts/Poppins-Bold.ttf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url("../../fonts/Poppins-Regular.ttf") format("opentype");
  font-weight: normal;
}

html {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  //font-size: 14px;
}

body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #fff;
  text-align: center;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #103B7B;
}

.colored-title {
  color: #FDC500;
}

body a {
  text-decoration: none;
}

body .h1 {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 4.5rem;
  line-height: 98%;
  letter-spacing: -0.05em;
  margin-bottom: 1rem;
}

body .title-description {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
}

body .title-description-middle-bold {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

body .title-description-middle {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

body .title-description-bold {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

body .h3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 2.75rem;
  line-height: 98%;
  letter-spacing: -0.05em;

  color: #FDC500;
}

body .small-wrapper {
  width: 90rem;
  margin: 0 auto;
}

body .big-wrapper {
  width: 95rem;
  margin: 0 auto;
}

@media screen and (max-width: 1700px) {
  body .big-wrapper {
    width: 90rem;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1500px) {
  body .big-wrapper {
    width: 95vw;
  }

  body .small-wrapper {
    width: 95vw;
  }
}

@media screen and (max-width: 1300px) {
  body .big-wrapper {
    width: 95vw;
  }

  body .small-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 1100px) {
  body .big-wrapper {
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  body .small-wrapper {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 1008px) {

  body .h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  body .title-description {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  body .title-description-middle-bold {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  body .title-description-middle {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  body .title-description-bold {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  body .h3 {
    font-size: 1.75rem;
  }

}

@media screen and (max-width: 700px) {
  body .h1 {
    font-size: 2rem;
  }
}

